import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';
import Subheader from '../components/Subheader';

const Expertise = ({ data, pageContext }) => {
  const { datoCmsFixtext, allDatoCmsBeitragBvogOfakt } = data;
  const { name: pageName } = pageContext;

  return (
    <Layout
      title={(datoCmsFixtext && datoCmsFixtext.seo && datoCmsFixtext.seo.title) || pageName}
      description={datoCmsFixtext && datoCmsFixtext.seo && datoCmsFixtext.seo.description}
    >
      <div className="common-page article-page">
        <Subheader
          title={pageName.toUpperCase()}
          intro={datoCmsFixtext && datoCmsFixtext.fixtext}
        />

        <div className="container">
          <div className="er-template">
            <div className="article-feed">
              {allDatoCmsBeitragBvogOfakt.edges.map(({ node }) => (
                <Post key={node.id} node={node} page={pageName} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Expertise;

export const query = graphql`
  query($link: String!, $name: String!) {
    allDatoCmsBeitragBvogOfakt(
      sort: { fields: [meta___createdAt], order: DESC }
      filter: { bvog: { eq: true }, arbeitsweise: { elemMatch: { link: { eq: $link } } } }
    ) {
      edges {
        node {
          ...DatoBeitragFields
        }
      }
    }

    datoCmsFixtext(name: { eq: $name }) {
      fixtext
      introtext
      seo {
        title
        description
      }
    }
  }
`;
